<template>
  <!-- <div class="col-md-6 mb-3 mb-lg-3"> -->
  <!-- Icon Block Item -->
  <div class="card h-100" href="#">
    <div class="card-body">
      <div class="media">
        <figure class="w-100 max-w-6rem mr-3">
          <img class="img-fluid" :src="require('@/assets/svg/icons/' + item.image_url)">
          <!-- <img class="img-fluid" src="@/assets/svg/icons/icon-24.svg" alt="SVG"> -->
        </figure>
        <div class="media-body">
          <h3>{{ item.title }}</h3>
          <p class="text-body">{{ item.content }}</p>
        </div>
      </div>
    </div>
  </div>
  <!-- End Icon Block Item -->
  <!-- </div> -->
</template>

<script>
import 'bootstrap'

export default {
  name: 'PracticesCard',
  props: {
    item: {
      type: Object,
      default: () => {
        return {
          image_url: '',
          title: '',
          content: ''
        }
      }
    }
  }
}
</script>
