<template>
  <main id="content" role="main" class="overflow-hidden">

    <!-- ========== Hero Section ========== -->
    <div class="position-relative bg-img-hero" :style="{'background-image': 'url(' + require('@/assets/svg/components/ben-about-hero.svg') + ')'}">
      <div class="container space-top-3 space-bottom-2 position-relative z-index-2">
        <div class="row justify-content-lg-between mb-7 align-items-center">
          <div class="col-md-6 col-lg-6">
            <div class="mb-5">
              <span class="d-block small font-weight-bold text-cap mb-2">WHO WE ARE</span>
              <h1>An Award-Winning API &amp; Cloud Native Company</h1>
            </div>
          </div>

          <div class="col-md-6 d-md-inline-block" data-aos="fade-up">
            <figure class="w-100">
              <img class="img-fluid" src="@/assets/svg/illustrations/about-hero-2.svg">
            </figure>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Hero Section ========== -->

    <!-- ========== About Us Section ========== -->
    <div class="container space-2 space-top-lg-3">
      <div class="row justify-content-lg-between">
        <div class="col-lg-6 mb-5 mb-lg-0">
          <span class="d-block small font-weight-bold text-cap mb-2">About Us</span>
          <h2 class="mb-3">One-stop API Platform Services Connecting Digital Businesses</h2>
        </div>
        <div class="col-lg-6">
          <p>beNovelty is an award-winning company facilitating business collaborations between enterprises &amp; third party service providers (TSP) under API Economy.</p>
          <p>beNovelty provides one-stop platform (including training, design, implementation &amp; operation services) to enable enterprises &amp; TSP co-creating new product and service models across industries. Our clients & partners include leading banks, insurance, F&B, tourism, healthcare, real estate and keep expanding. </p>
          <p>beNovelty is a strategic partner of Hong Kong Science Park (HKSTP) in Open API initiatives and a featured company under HKSTP LEAP Programme right now.</p>
        </div>
      </div>
    </div>
    <!-- ========== End About Us Section ========== -->

    <!-- ========== History Section ========== -->
    <!-- SVG Top Shape -->
    <figure>
      <svg
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="100%"
        height="64px"
        viewBox="0 0 1921 273"
        style="margin-bottom: -8px; enable-background:new 0 0 1921 273;"
        xml:space="preserve"
      >
        <polygon fill="#f9fbff" points="0,273 1921,273 1921,0 " />
      </svg>
    </figure>
    <!-- End SVG Top Shape -->

    <div class="bg-light position-relative">
      <div class="container">
        <div class="card shadow">
          <div class="container space-top-2">
            <div class="w-md-80 w-lg-50 text-center mx-md-auto">
              <span class="d-block small font-weight-bold text-cap mb-2">OUR HISTORY</span>
              <h2>This is Where We Begin</h2>
            </div>
            <!-- New Timeline -->
            <div class="row space-bottom-2 scroll">
              <div class="col-8 col-lg-5 timeline-item pb-3 ml-5 ml-lg-9">
                <div class="media mb-3">
                  <span class="icon icon-xs icon-soft-indigo icon-circle mr-2">
                    <i class="fas fa-building text-primary" />
                  </span>
                  <div class="media-body">
                    <p class="text-dark mb-0">Founded by Patrick CS Fan, former IBM Tech Leader</p>
                  </div>
                </div>
                <div class="media mb-3">
                  <span class="icon icon-xs icon-soft-warning icon-circle mr-2">
                    <i class="fas fa-trophy text-yellow" />
                  </span>
                  <div class="media-body">
                    <p class="text-dark mb-0">Google EYE Program Top 6 Startup Team</p>
                  </div>
                </div>
                <h3 class="text-primary mt-5">2015</h3>
              </div>
              <div class="col-8 col-lg-5 timeline-item pb-3">
                <div class="media mb-3">
                  <span class="icon icon-xs icon-soft-warning icon-circle mr-2">
                    <i class="fas fa-trophy text-yellow" />
                  </span>
                  <div class="media-body">
                    <p class="text-dark mb-0">Hong Kong Best ICT Startup Award (Software & App)</p>
                  </div>
                </div>
                <h3 class="text-primary mt-5">2016</h3>
              </div>
              <div class="col-9 col-lg-5 timeline-item pb-3">
                <div class="media mb-3">
                  <span class="icon icon-xs icon-soft-warning icon-circle mr-2">
                    <i class="fas fa-trophy text-yellow" />
                  </span>
                  <div class="media-body">
                    <p class="text-dark mb-0">HKQAA 香港品質保證局 Registered Platinum Startup</p>
                  </div>
                </div>
                <div class="media mb-3">
                  <span class="icon icon-xs icon-soft-warning icon-circle mr-2">
                    <i class="fas fa-users text-orange" />
                  </span>
                  <div class="media-body">
                    <p class="text-dark mb-0">AWS APN Select Consulting Partner</p>
                  </div>
                </div>
                <h3 class="text-primary font-size-2 mt-5">2018</h3>
              </div>
              <div class="col-11 col-lg-6 timeline-item pb-3">
                <div class="media mb-3">
                  <span class="icon icon-xs icon-soft-warning icon-circle mr-2">
                    <i class="fas fa-trophy text-yellow" />
                  </span>
                  <div class="media-body">
                    <p class="text-dark mb-0">IFTA Fintech Achievement Awards 2019 (Cloud Computing)</p>
                  </div>
                </div>
                <div class="media">
                  <span class="icon icon-xs icon-soft-warning icon-circle mr-2">
                    <i class="fas fa-users text-orange" />
                  </span>
                  <div class="media-body">
                    <p class="text-dark mb-0">Alibaba Cloud Channel Partner</p>
                  </div>
                </div>
                <div class="media">
                  <span class="icon icon-xs icon-soft-warning icon-circle mr-2">
                    <i class="fas fa-users text-orange" />
                  </span>
                  <div class="media-body">
                    <p class="text-dark mb-0">Kong Enterprise Gold Partner</p>
                  </div>
                </div>
                <div class="media mb-3">
                  <span class="icon icon-xs icon-soft-warning icon-circle mr-2">
                    <i class="fas fa-users text-orange" />
                  </span>
                  <div class="media-body">
                    <p class="text-dark mb-0">Ionic Enterprise (Gold Tier) Trusted Partner</p>
                  </div>
                </div>
                <h3 class="text-primary mt-5 font-size-2">2019</h3>
              </div>
              <div class="col-12 col-lg-7 timeline-item pb-3">
                <div class="media mb-3">
                  <span class="icon icon-xs icon-soft-info icon-circle mr-2">
                    <i class="fas fa-calendar-star text-info" />
                  </span>
                  <div class="media-body">
                    <p class="text-dark mb-0">Hosted <strong>apidays Hong Kong</strong>—the first-ever largest API Conference in Hong Kong<br>
                      <router-link class="font-weight-bold" :to="{ path: '/events/apidays-live-hongkong' }">
                        Learn More<i class="fas fa-angle-right fa-sm ml-2" />
                      </router-link>
                    </p>
                    <!-- <img class="img-fluid w-50 mt-2" src="@/assets/img/thumbnails/apidays-live-hongkong.jpg"> -->
                  </div>
                </div>
                <div class="media">
                  <span class="icon icon-xs icon-soft-warning icon-circle mr-2">
                    <i class="fas fa-trophy text-yellow" />
                  </span>
                  <div class="media-body">
                    <p class="text-dark mb-0">Hong Kong ICT Awards 2020 - ICT Startup (Software and Apps) Silver</p>
                    <!-- <img class="img-fluid w-60 mt-2" src="@/assets/img/timeline/awards-2020.jpg"> -->
                  </div>
                </div>
                <div class="media">
                  <span class="icon icon-xs icon-soft-warning icon-circle mr-2">
                    <i class="fas fa-trophy text-yellow" />
                  </span>
                  <div class="media-body">
                    <p class="text-dark mb-0">Fintech Awards 2020 - Outstanding Cloud Based API Platform</p>
                    <!-- <img class="img-fluid w-60 mt-2" src="@/assets/img/timeline/awards-2020.jpg"> -->
                  </div>
                </div>
                <div class="media">
                  <span class="icon icon-xs icon-soft-warning icon-circle mr-2">
                    <i class="fas fa-trophy text-yellow" />
                  </span>
                  <div class="media-body">
                    <p class="text-dark mb-0">ERB Manpower Developer Award 2020</p>
                    <!-- <img class="img-fluid w-60 mt-2" src="@/assets/img/timeline/awards-2020.jpg"> -->
                  </div>
                </div>
                <div class="media mb-3">
                  <span class="icon icon-xs icon-soft-warning icon-circle mr-2">
                    <i class="fas fa-trophy text-yellow" />
                  </span>
                  <div class="media-body">
                    <p class="text-dark mb-0">HKSTP Leading Enterprises Acceleration Programme (LEAP)</p>
                    <!-- <img class="img-fluid w-60 mt-2" src="@/assets/img/timeline/awards-2020.jpg"> -->
                  </div>
                </div>
                <h3 class="text-primary font-size-2">2020</h3>
              </div>
              <div class="col-11 col-lg-6 timeline-item pb-3">
                <div class="media mb-3">
                  <span class="icon icon-xs icon-soft-success icon-circle mr-2">
                    <i class="fas fa-bookmark text-success" />
                  </span>
                  <div class="media-body">
                    <p class="text-dark mb-0">Featured in HSBC Business Story & HSBC API EcoBooster<br>
                      <router-link class="font-weight-bold" :to="{ path: '/case-study/hsbc' }">
                        Read Story<i class="fas fa-angle-right fa-sm ml-2" />
                      </router-link></p>
                  </div>
                </div>
                <h3 class="text-primary font-size-2 mt-5">2021</h3>
              </div>
              <div class="col-11 col-lg-6 timeline-item pb-3">
                <div class="media mb-3">
                  <span class="icon icon-xs icon-soft-warning icon-circle mr-2">
                    <i class="fas fa-trophy text-yellow" />
                  </span>
                  <div class="media-body">
                    <p class="text-dark mb-0">Recognized as Top Rank Innovative Company in Hong Kong (CII 3 Stars Innovative SME)</p>
                  </div>
                </div>
                <div class="media">
                  <span class="icon icon-xs icon-soft-warning icon-circle mr-2">
                    <i class="fas fa-users text-orange" />
                  </span>
                  <div class="media-body">
                    <p class="text-dark mb-0">AWS SaaS Accelerator 2022</p>
                  </div>
                </div>
                <div class="media mb-3">
                  <span class="icon icon-xs icon-soft-warning icon-circle mr-2">
                    <i class="fas fa-users text-orange" />
                  </span>
                  <div class="media-body">
                    <p class="text-dark mb-0">AWS Technology Partner & AWS Qualified Software</p>
                  </div>
                </div>
                <h3 class="text-primary font-size-2 mt-5">2022</h3>
              </div>
              <div class="col-11 col-lg-5 timeline-item pb-3">
                <div class="w-90">
                  <p>beNovelty's products have been supporting thousands of customers in getting ahead in the API economy. Our story continues with you :)</p>
                </div>
                <h3 class="text-primary font-size-2 mt-5">To be continued...</h3>
              </div>
            </div>
            <!-- Old Timeline -->
            <!-- <ul class="step">
                <li v-for="item in timeline_list" :key="item.event" class="step-item" data-aos="fade-up">
                <div class="step-content-wrapper">
                    <span class="step-icon step-icon-sm step-icon-soft-primary"><i class="far fa-calendar-alt" /></span>
                    <div class="step-content mb-5">
                    <div class="row align-items-md-center">
                        <div class="col-md-6">
                        <h5>{{ item.event }}</h5>
                        </div>
                        <div class="col-md-6 text-md-right">
                        <p>{{ item.year }}</p>
                        </div>
                    </div>
                    <p />
                    </div>
                </div>
                </li>
              </ul> -->
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End History Section ========== -->

    <!-- ========== Our Practices Section ========== -->
    <div class="container space-2 space-top-lg-3">
      <div class="w-md-80 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
        <span class="d-block small font-weight-bold text-cap mb-2">HOW WE DO</span>
        <h2>Our Practices</h2>
        <p>A Group of Certified Experts with 10+ Years of Exceptional Experience</p>
      </div>
      <div class="row" data-aos="fade-up">
        <div v-for="item in practice_list" :key="item.title" class="col-md-6 mb-3 mb-lg-3">
          <PracticesCard :item="item" />
        </div>
      </div>
    </div>
    <!-- ========== End Our Practices Section ========== -->

    <!-- ========== Awards Section ==========-->
    <div
      class="space-2"
      :style="{'background-image': 'url(' + require('@/assets/svg/components/abstract-shapes-9-1-01.svg') + ')',
               backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}"
    >
      <div class="w-md-80 w-lg-50 text-center mx-md-auto mb-5">
        <span class="d-block small font-weight-bold text-cap mb-2">Our Awards</span>
        <h2>Awards &amp; Accolades</h2>
      </div>
      <div class="position-relative">
        <div class="container space-2">
          <div
            class="js-slick-carousel slick slick-equal-height slick-gutters-3"
            data-hs-slick-carousel-options="{
                &quot;prevArrow&quot;: &quot;<span class=\&quot;fa fa-arrow-left slick-arrow slick-arrow-primary-white slick-arrow-left slick-arrow-centered-y shadow-soft rounded-circle ml-sm-n2\&quot;></span>&quot;,
                &quot;nextArrow&quot;: &quot;<span class=\&quot;fa fa-arrow-right slick-arrow slick-arrow-primary-white slick-arrow-right slick-arrow-centered-y shadow-soft rounded-circle mr-sm-n2\&quot;></span>&quot;,
                &quot;slidesToShow&quot;: 3,
                &quot;responsive&quot;: [{
                  &quot;breakpoint&quot;: 1200,
                    &quot;settings&quot;: {
                      &quot;slidesToShow&quot;: 3
                    }
                  }, {
                  &quot;breakpoint&quot;: 992,
                    &quot;settings&quot;: {
                      &quot;slidesToShow&quot;: 2
                    }
                  }, {
                  &quot;breakpoint&quot;: 768,
                  &quot;settings&quot;: {
                    &quot;slidesToShow&quot;: 1
                  }
                  }]
              }"
          >
            <div v-for="item in award_item_list" :key="item.title" class="js-slide">
              <div class="card bg-img-hero w-100 h-100" :style="{'background-image': 'url(' + require('@/assets/svg/components/general-bg-6.svg') + ')'}">
                <div class="card-body text-center pb-0">
                  <div class="avatar avatar-xxl mb-3">
                    <img class="avatar-img" :src="require('@/assets/img/awards/' + item.image_url)">
                  </div>
                  <h3 class="text-white text-center">{{ item.title }}</h3>
                  <p class="text-white text-center">{{ item.subtitle }}</p>
                </div>
                <div class="card-footer pt-0 border-0 bg-transparent">
                  <hr class="solid">
                  <h4 class="text-white-70 text-center">{{ item.organization }}</h4>
                  <span class="text-white-70 d-block font-weight-bold text-center">{{ item.year }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="w-100 w-md-65 bg-light position-absolute top-0 right-0 bottom-0 rounded-left z-index-n1" />
      </div>
    </div>
    <!-- ========== End Awards Section ========== -->

    <!-- ========== Trust Section ========== -->
    <div class="container position-relative z-index-2 space-top-3 space-bottom-1">
      <div class="w-md-80 w-lg-60 text-center mx-md-auto mb-5">
        <span class="d-block small font-weight-bold text-cap mb-2">Trust At beNovelty</span>
        <h2>Trusted by Our Partners &amp; Clients</h2>
        <p class="mb-9">Many of the innovative companies are scaling faster and more efficiently by building their businesses with beNovelty.</p>
        <!-- <a id="ga-about-trust-button" class="font-weight-bold" href="/trust"> -->
        <router-link id="ga-about-trust-button" class="font-weight-bold" :to="{ path: '/trust' }">
          Discover how we build trust with our partners and clients <i class="fas fa-angle-right fa-sm ml-1" />
        </router-link>
        <!-- </a> -->
      </div>
    </div>
    <!-- ========== End Trust Section ========== -->

    <!-- ========== Partners Section ========== -->
    <!-- <div class="container space-2">
      <div class="text-center mb-4">
        <h2 class="divider divider-text">Featured Partners</h2>
      </div>
      <PartnerCarousel :items="partner_list" />
    </div> -->
    <!-- ========== End Partners Section ========== -->

    <!-- ==========  CTA Section ==========  -->
    <div class="gradient-y-sm-primary">
      <div class="container space-2 space-lg-3">
        <div class="w-md-80 text-center mx-md-auto mb-5">
          <span class="d-block small font-weight-bold text-cap mb-2">you're in good company</span>
          <h2>Powering Innovative Enterprises</h2>
        </div>

        <PartnerCarousel :items="partner_list" />

        <div class="mt-4">
          <ContactUsBar :detail="{ title: 'Interested in Partnering with Us?' }" />
        </div>
      </div>
    </div>
    <!-- ========== End CTA Section ==========  -->

  </main>
</template>

<script>
import 'bootstrap'
const $ = require('jquery')
import PracticesCard from '@/components/practices-card'
import PartnerCarousel from '@/components/partner-carousel'
import ContactUsBar from '@/components/contact-us-bar'
import HSGoTo from '../../assets/vendor/hs-go-to/src/js/hs-go-to'
import '../../assets/js/hs.core.js'
import '../../assets/js/hs.slick-carousel.js'
import '../../assets/vendor/slick-carousel/slick/slick.js'
import { mapGetters } from 'vuex'
export default {
  name: 'About',
  components: {
    PracticesCard,
    PartnerCarousel,
    ContactUsBar
  },
  data() {
    return {
      practice_list: [],
      partner_list: [],
      timeline_list: [
        {
          event: 'Founded by Patrick CS Fan, former IBM Tech Leader',
          year: '2015'
        },
        {
          event: 'Google EYE Program Top 6 Startup Team',
          year: '2015'
        },
        {
          event: 'Hong Kong Best ICT Startup Award (Software & App)',
          year: '2016'
        },
        {
          event: 'HKQAA 香港品質保證局 Registered Platinum Startup',
          year: '2018'
        },
        {
          event: 'AWS APN Select Consulting Partner',
          year: '2018'
        },
        {
          event: 'Alibaba Cloud Channel Partner',
          year: '2019'
        },
        {
          event: 'Kong Enterprise Gold Partner',
          year: '2019'
        },
        {
          event: 'Ionic Enterprise (Gold Tier) Trusted Partner',
          year: '2019'
        },
        {
          event: 'HKSTP Leading Enterprises Acceleration Programme',
          year: '2020'
        },
        {
          event: '#1 HKQAA 香港品質保證局 Registered Platinum Startup PLUS',
          year: '2020'
        },
        {
          event: 'IFTA fintech achievement awards 2019 (Cloud Computing)',
          year: '2020'
        }
      ],
      award_item_list: [
        {
          image_url: 'erb-md.jpg',
          title: 'ERB Manpower Developer Award',
          subtitle: 'Manpower Developer (2020-2025)',
          organization: 'Employees Retraining Board (ERB)',
          year: '2020, 2023'
        },
        {
          image_url: 'ciia-2022.jpg',
          title: 'Corporate Innovation Index (CII) Awards 2022',
          subtitle: 'Three-Star (Top Rank) Award',
          organization: 'CUHK Business School',
          year: '2022'
        },
        {
          image_url: 'fintech-awards-2020.jpg',
          title: 'Fintech Awards 2020',
          subtitle: 'Outstanding Cloud-Based API Platform',
          organization: 'ET Net',
          year: '2020'
        },
        {
          image_url: 'hk-ict-awards-2020-silver.jpg',
          title: 'Hong Kong ICT Awards 2020',
          subtitle: 'ICT Startup (Software and Apps) Silver Award',
          organization: 'OGCIO',
          year: '2020'
        },
        {
          image_url: 'ifta-2019.jpg',
          title: 'IFTA Fintech Achievement Awards 2019',
          subtitle: 'Cloud Computing Platinum Award',
          organization: 'IFTA',
          year: '2020'
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'partners',
      'practices'
    ])
  },
  async mounted() {
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })
    $('.js-slick-carousel').each(function() {
      $.HSCore.components.HSSlickCarousel.init($(this))
    })
  },
  created() {
    this.partner_list = this.$store.getters.partners.partner_list
    this.practice_list = this.$store.getters.practices.practice_list
  },
  metaInfo() {
    return {
      title: 'beNovelty',
      titleTemplate: 'About Us | %s',
      link: [
        { rel: 'canonical', href: 'https://www.benovelty.com/about' }
      ],
      meta: [
        { name: 'description', content: 'Discover beNovelty\'s state-of-the-art API-First and intelligent Agentic AI Solutions, designed to elevate your enterprise\'s digital transformation.' },
        { property: 'og:site_name', content: 'beNovelty' },
        { property: 'og:title', content: 'About Us | beNovelty' },
        { property: 'og:description', content: 'beNovelty provides one-stop platform (including training, design, implementation & operation services) to enable enterprises & TSP co-creating new product and service models across industries. Our clients & partners include leading banks, insurance, F&B, tourism, healthcare, real estate and keep expanding.' },
        { property: 'og:image', content: 'https://www.benovelty.com/assets/img/thumbnails/common.jpg' },
        { property: 'og:url', content: 'https://www.benovelty.com/about' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("../../assets/vendor/slick-carousel/slick/slick.css");

.scroll {
  align-items: flex-end;
  display: flex;
  flex-wrap: nowrap;
  max-width: none;
  overflow-x: scroll;
  padding: 50px 0;
  position: relative;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  cursor: pointer;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bbb;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.timeline-item:before {
  background-color: #57abf0;
    border-radius: 50%;
    bottom: -6px;
    content: "";
    display: block;
    height: 20px;
    left: -2px;
    position: absolute;
    width: 20px;
    z-index: 9;
}
.timeline-item:after {
  background-color: #57abf0;
    bottom: 0;
    content: "";
    height: 8px;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
}
</style>
